<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model='searchFields.status'
              :dir='$store.state.appConfig.layout.direction'
              :options='status_list'
              :reduce='item => item.value'
              label='label'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >

            <label>{{ $t('region') }}</label>
            <v-select
              v-model='searchFields.region_id'
              :dir='$store.state.appConfig.layout.direction'
              :options='regions'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2'
          >
            <label>{{ $t('city') }}</label>
            <v-select
              v-model='searchFields.city_id'
              :dir='$store.state.appConfig.layout.direction'
              :options='cities'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>
                <div class='no-options-message'>
                  لا يوجد بيانات
                </div>
              </template>
            </v-select>
          </b-col>
          <!--          <b-col-->
          <!--            cols='12'-->
          <!--            md='4'-->
          <!--            class='mb-md-0 mb-2 mt-1'-->
          <!--          >-->
          <!--            <label>{{ $t('district') }}</label>-->
          <!--            <v-select-->
          <!--              v-model='searchFields.district_id'-->
          <!--              :dir='$store.state.appConfig.layout.direction'-->
          <!--              :options='districts'-->
          <!--              :reduce='item => item.id'-->
          <!--              label='name'-->
          <!--              class='w-100'-->
          <!--            >-->
          <!--              <template #no-options>-->
          <!--                <div class="no-options-message">-->
          <!--                  لا يوجد بيانات-->
          <!--                </div>-->
          <!--              </template>-->
          <!--            </v-select>-->
          <!--          </b-col>-->

          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mt-1'
          >
            <DatePicker
              :label="$t('labels.created_on')"
              :reset='true'
              v-model='created_at'
            />
          </b-col>
          <b-col
            cols='12'
            md='4'
            class='mb-md-0 mb-2 mt-1'
          >
            <label>{{ $t('usage_id') }}</label>
            <v-select
              v-model='searchFields.usage_id'
              :dir='$store.state.appConfig.layout.direction'
              :options='usages'
              :reduce='item => item.id'
              label='name'
              class='w-100'
            >
              <template #no-options>{{ $t('No options') }}</template>
            </v-select>
          </b-col>


        </b-row>
      </b-card-body>
    </b-card>

    <TableIndex
      :resource-name='resourceName'
      :singular-name='singularName'
      :filter-fields='searchFields'
      :have-dropdown-actions='true'
      :showRealEstatePage='true'
      :permissionKey='"leave-it-on-us"'

    >
      <template v-slot:dropdown_actions='{ item, generalActions }'>
        <b-dropdown-item
          v-if='(item.actions && item.actions.can_change_status)'
          @click='showModal(item,item.status)'
        >
          <feather-icon
            icon='FilterIcon'
            class='mr-50'
          />
          <span>{{ $t('status') }}</span>
        </b-dropdown-item>
        <b-dropdown-item
          v-if='(item.actions && item.actions.can_view)'
          variant='link'
          :to="{ name: 'details-real-estate-leave-on-us', params: { resourceId: item.id } }"
        >
          <feather-icon
            icon='EyeIcon'
            class='mr-50'
          />
          <span>{{ $t('details') }}</span>
        </b-dropdown-item>
      </template>
    </TableIndex>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'
import { websiteMainUrl } from '@/services/url.service'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'real-estate-leave-on-us',
      singularName: 'real-estate-leave-on-us',
      status_list: [
        {
          label: this.$t('leave_it_on_us_status.pending'),
          value: 1,
        },
        {
          label: this.$t('leave_it_on_us_status.created'),
          value: 2,
        },
      ],
      types_list: [
        {
          label: this.$t('leave_it_on_us_request.marketing-request'),
          value: 1,
        },
        {
          label: this.$t('leave_it_on_us_request.un-authorized-ad'),
          value: 2,
        },
      ],
      cities: [],
      districts: [],
      usages: [],
      regions: [],
      created_at: null,
      searchFields: {
        status: null,
        city_id: null,
        district_id: null,
        usage_id: null,
        region_id: null,
        created_at: null,
        privacy_type: 0,
      },
    }
  },

  created() {
    if (this.$route.path === '/real-estate-leave-on-us-private') {
      this.searchFields.privacy_type = 1;
      this.singularName = 'real-estate-leave-on-us-private';
    }

    this.getRegion()
    this.getUsages()
  },

  watch: {
    'searchFields.region_id': {
      handler(val) {
        this.searchFields.city_id = null
        this.searchFields.district_id = null
        this.getCities(val)
      },
    },
    'searchFields.city_id': {
      handler(val) {
        this.searchFields.district_id = null
        this.getDistrict(val)
      },
    },
    'created_at': {
      handler(val) {
        if (this.searchFields['created_at'] == null && (val == '' || val == null)) {
          return true
        }

        this.searchFields['created_at'] = val
      },
    },

  },

  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function(e, field) {
      this.searchFields[field] = e
    }, 500),

    getRegion() {
      this.axios.get(`/model-options/region`)
        .then(res => {
          this.regions = res.data.data
        })
    },
    getCities(val = null) {

      if (val === null) {
        this.cities = []
      }

      this.axios.get(`/model-options/city?region_id=${val}`)
        .then(res => {
          this.cities = res.data.data
          return 1
        })
    },
    getDistrict(val = null) {

      if (val === null) {
        this.districts = []
        return 1
      }
      this.axios.get(`/model-options/district?city_id=${val}`)
        .then(res => {
          this.districts = res.data.data
        })
    },

    getUsages() {
      this.axios.get(`/model-options/usage`)
        .then(res => {
          this.usages = res.data.data
        })
    },
  },

}
</script>
